// microserviceService.js

// The base URL of your microservice (the Node app you shared).
// In production, this might be something like "https://api.myapp.com"
// or an environment variable.
const MICRO_BASE_URL = process.env.REACT_APP_MICRO_BASE_URL || "http://localhost:3000";

/**
 * Sign in with the microservice.
 *
 * This will cause your microservice to call Everi's sign-in endpoint,
 * and store the access token in its own memory.
 * The microservice will return `{ message, access_token }` on success.
 */
export async function signIn() {
    const response = await fetch(`${MICRO_BASE_URL}/auth`, {
        method: "POST"
    });

    if (!response.ok) {
        const error = await response.text();
        throw new Error(`Sign-in error: ${error}`);
    }

    return await response.json();
    // e.g. { message: "Authentication successful", access_token: "xxxx-..." }
}

/**
 * Create a reward by calling your microservice at /create-reward
 *
 * The body should contain fields like:
 * {
 *   externalUserId,
 *   externalUserProvider,
 *   externalReferenceId,
 *   couponCode,
 *   benefitDiscounts, // array
 *   benefitValue,
 *   startDate,
 *   expiryDate,
 *   templateId
 * }
 *
 * Example usage:
 * createReward({
 *   externalUserId: "24855534",
 *   externalUserProvider: "Ticketmaster",
 *   externalReferenceId: "someUniqueId",
 *   couponCode: "1234567890",
 *   benefitDiscounts: ["20%OFF"],
 *   benefitValue: 20,
 *   startDate: "2025-01-01T12:00:00Z",
 *   expiryDate: "2025-01-10T12:00:00Z",
 *   templateId: "12345"
 * })
 */
export async function createReward(rewardData, accessToken) {
    rewardData['token'] = accessToken;
    console.log(rewardData)
    const response = await fetch(`${MICRO_BASE_URL}/create-reward`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
            // We do NOT send the access token. The microservice
            // has it cached after /auth was called.
        },
        body: JSON.stringify(rewardData)
    });

    if (!response.ok) {
        const error = await response.text();
        throw new Error(`Create Reward error: ${error}`);
    }

    return await response.json();
    // e.g. { message: 'Reward created successfully', statusCode: 201 }
}

/**
 * Update an existing reward:
 *
 * Body shape is similar to create:
 * {
 *   externalUserId,
 *   externalUserProvider,
 *   externalReferenceId,
 *   benefitDiscounts,
 *   benefitValue,
 *   startDate,
 *   expiryDate,
 *   templateId
 * }
 */
export async function updateReward(rewardData) {
    const response = await fetch(`${MICRO_BASE_URL}/update-reward`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(rewardData)
    });

    if (!response.ok) {
        const error = await response.text();
        throw new Error(`Update Reward error: ${error}`);
    }

    return await response.json();
    // e.g. { message: 'Reward updated successfully', statusCode: 200 }
}

/**
 * Delete a reward:
 *
 * The microservice expects query params:
 *   externalUserId, externalUserProvider, externalReferenceId
 *
 * Example usage:
 * deleteReward({
 *   externalUserId: "24855534",
 *   externalUserProvider: "Ticketmaster",
 *   externalReferenceId: "someUniqueId"
 * })
 */
export async function deleteReward({ externalUserId, externalUserProvider, externalReferenceId }) {
    // Build query string
    const params = new URLSearchParams({
        externalUserId,
        externalUserProvider,
        externalReferenceId
    }).toString();

    const response = await fetch(`${MICRO_BASE_URL}/delete-reward?${params}`, {
        method: "DELETE"
    });

    if (!response.ok) {
        const error = await response.text();
        throw new Error(`Delete Reward error: ${error}`);
    }

    return await response.json();
    // e.g. { message: 'Reward deleted successfully', statusCode: 204 }
}
